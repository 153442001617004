import React, { useState, useEffect } from "react";
import { Button } from "./components/ui/button";
import { Card, CardContent } from "./components/ui/card";
import {
  RefreshCw,
  Copy,
  Check,
  ArrowRight,
  Palette,
  Type,
  Wand2,
} from "lucide-react";
import { motion } from "framer-motion";

const fonts = [
  "Playfair Display, serif",
  "Roboto, sans-serif",
  "Montserrat, sans-serif",
  "Futura, sans-serif",
  "Helvetica Neue, sans-serif",
  "Bodoni MT, serif",
  "Avenir, sans-serif",
  "Gotham, sans-serif",
  "Didot, serif",
  "Proxima Nova, sans-serif",
];

const generateCuratedPalette = () => {
  const palettes = [
    ["#F2E8CF", "#A7C957", "#6A994E", "#386641", "#1F2421"],
    ["#EF476F", "#FFD166", "#06D6A0", "#118AB2", "#073B4C"],
    ["#F72585", "#7209B7", "#3A0CA3", "#4361EE", "#4CC9F0"],
    ["#CFDBD5", "#E8EDDF", "#F5CB5C", "#242423", "#333533"],
    ["#F4F1DE", "#E07A5F", "#3D405B", "#81B29A", "#F2CC8F"],
    ["#50514F", "#F25F5C", "#FFE066", "#247BA0", "#70C1B3"],
    ["#EDAE49", "#D1495B", "#00798C", "#30638E", "#003D5B"],
    ["#9B5DE5", "#F15BB5", "#FEE440", "#00BBF9", "#00F5D4"],
    ["#FFFFFF", "#00171F", "#003459", "#007EA7", "#00A8E8"],
    ["#731DD8", "#48BFE3", "#5E60CE", "#64DFDF", "#80FFDB"],
    ["#E5D9F2", "#EDE5F9", "#CDC1FF", "#A594F9", "#7371FC"],
    ["#071232", "#088345", "#37B733", "#EBF1F6"],
    ["#131841", "#E28369", "#ECCEA0", "#FBF6E7"],
    ["#000001", "#FF4391", "#E93374", "#FFF373"],
    ["#131841", "#E28369", "#ECCEA0", "#FBF6E7"],
    ["#17153B", "#2E236C", "#433D8B", "#C8ACD6"],
    ["#f44336", "#ffa500", "#6aa84f", "#2986cc", "#674ea7"],
    ["#3c2f2e", "#f25f4c", "#d98560", "#a52a22", "#3c2f2e"],
  ];
  return palettes[Math.floor(Math.random() * palettes.length)];
};

const getContrastColor = (hexColor) => {
  if (!hexColor || !/^#[0-9A-F]{6}$/i.test(hexColor)) {
    return "#000000";
  }

  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5 ? "#000000" : "#FFFFFF";
};

const ColorCard = ({ color, index }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.3, delay: index * 0.1 }}
    className="aspect-square rounded-2xl shadow-lg flex flex-col items-center justify-center text-sm cursor-pointer hover:scale-105 transition-transform duration-200"
    style={{
      backgroundColor: color,
      color: getContrastColor(color),
    }}
  >
    <span className="font-bold mb-2">{`Color ${index + 1}`}</span>
    <span>{color}</span>
  </motion.div>
);

const App = () => {
  const [colors, setColors] = useState([]);
  const [font, setFont] = useState("");
  const [copied, setCopied] = useState(false);

  const handleBadgeClick = (e) => {
    e.preventDefault();
    const email = "info@uimagic.io";
    const subject = encodeURIComponent("Inquiry about creating an app");
    const body = encodeURIComponent("I would like to create an app...");
    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  };

  const generateNewStyle = () => {
    const newColors = generateCuratedPalette();
    setColors(newColors);
    setFont(fonts[Math.floor(Math.random() * fonts.length)]);
  };

  useEffect(() => {
    generateNewStyle();
  }, []);

  const copyToClipboard = () => {
    const styleData = `
      Colors: ${colors.join(", ")}
      Font: ${font}
    `;
    navigator.clipboard.writeText(styleData).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const bgColor = colors[0] || "#FFFFFF";
  const textColor = colors[4] || "#000000";

  return (
    <div
      className="min-h-screen p-4 md:p-8 transition-all duration-500 ease-in-out"
      style={{ backgroundColor: bgColor, color: textColor, fontFamily: font }}
    >
      <motion.h1
        className="text-4xl md:text-6xl font-bold mb-8 md:mb-12 text-center text-white flex items-center justify-center"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{ color: getContrastColor(colors[0]) }}
      >
        Magicolor
        <Wand2 className="mr-4 ml-4 h-12 w-12" />
      </motion.h1>

      <motion.div
        className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-8 md:mb-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <Card
          className="col-span-1 lg:col-span-2 overflow-hidden bg-opacity-95 backdrop-blur-lg shadow-2xl"
          style={{ backgroundColor: colors[0] || "#FFFFFF" }}
        >
          <CardContent className="p-6 md:p-8">
            <h2
              className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 flex items-center"
              style={{ color: getContrastColor(colors[0]) }}
            >
              <Palette className="mr-2" /> Color Palette
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-4 md:gap-6">
              {colors.map((color, index) => (
                <ColorCard
                  key={`${color}-${index}`}
                  color={color}
                  index={index}
                />
              ))}
            </div>
          </CardContent>
        </Card>

        <Card
          className="bg-opacity-95 backdrop-blur-lg shadow-2xl"
          style={{ backgroundColor: colors[2] || "#F0F0F0" }}
        >
          <CardContent className="p-6 md:p-8">
            <h2
              className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 flex items-center"
              style={{ color: getContrastColor(colors[2]) }}
            >
              <Type className="mr-2" /> Typography
            </h2>
            <p
              className="text-lg md:text-xl mb-4"
              style={{ color: getContrastColor(colors[2]) }}
            >
              Font: {font}
            </p>
            <div className="mt-4 md:mt-6">
              <p
                className="text-3xl md:text-5xl font-bold mb-4 leading-tight"
                style={{ color: getContrastColor(colors[2]), fontFamily: font }}
              >
                Aa Bb Cc Dd Ee
              </p>
              <p
                className="text-sm md:text-base mt-4"
                style={{ color: getContrastColor(colors[2]), fontFamily: font }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </CardContent>
        </Card>
      </motion.div>

      {/* <div className="absolute bottom-4 left-4 bg-white bg-opacity-90 text-gray-800 px-3 py-2 rounded-lg flex items-center space-x-2 shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer">
        <img
          src="https://2d4bd1e.b-cdn.net/2606168/favicon.png"
          alt="Magicolor Icon"
          className="w-5 h-5"
        />
        <span className="text-sm font-semibold">UiMagic APP gen </span>

      </div> */}

      <a
        href="mailto:info@uimagic.io?subject=Inquiry%20about%20creating%20an%20app&body=I%20would%20like%20to%20create%20an%20app..."
        onClick={handleBadgeClick}
        className="absolute bottom-4 left-4 bg-white bg-opacity-90 text-gray-800 px-3 py-2 rounded-lg flex items-center space-x-2 shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer no-underline hover:bg-opacity-100"
      >
        <img
          src="https://2d4bd1e.b-cdn.net/2606168/favicon.png"
          alt="UiMagic Icon"
          className="w-4 h-4"
        />
        <span className="text-sm font-semibold">UiMagic - APP generator</span>
      </a>

      <motion.div
        className="flex flex-col md:flex-row justify-center items-center gap-4 md:gap-6"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <Button
          onClick={generateNewStyle}
          className="w-full md:w-auto text-lg md:text-xl px-6 md:px-8 py-4 md:py-6 rounded-full transition-all duration-300 flex items-center justify-center shadow-xl hover:shadow-2xl transform hover:-translate-y-1"
          style={{
            backgroundColor: colors[3] || "#CCCCCC",
            color: getContrastColor(colors[3]),
          }}
        >
          <RefreshCw className="mr-2 md:mr-3 h-5 w-5 md:h-6 md:w-6" /> Generate
          New Style
        </Button>
        <Button
          onClick={copyToClipboard}
          className="w-full md:w-auto text-lg md:text-xl px-6 md:px-8 py-4 md:py-6 rounded-full transition-all duration-300 flex items-center justify-center shadow-xl hover:shadow-2xl transform hover:-translate-y-1"
          style={{
            backgroundColor: colors[4] || "#999999",
            color: getContrastColor(colors[4]),
          }}
        >
          {copied ? (
            <Check className="mr-2 md:mr-3 h-5 w-5 md:h-6 md:w-6" />
          ) : (
            <Copy className="mr-2 md:mr-3 h-5 w-5 md:h-6 md:w-6" />
          )}
          {copied ? "Copied!" : "Copy Style"}
        </Button>
      </motion.div>
    </div>
  );
};

export default App;
